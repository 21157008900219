import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

// components
import SEO from 'src/components/seo';
import { HomePageImage } from 'src/components/Photography';
import { Navbar } from 'src/components/common';

const sections = [
  {
    name: 'events',
    imageSize: '100%',
    path: '/events',
  },

  {
    name: 'ads',
    imageSize: '100%',
    path: '/ads',
  },
  {
    name: 'portraits',
    imageSize: '50%',
    path: '/portraits',
  },
  {
    name: 'documentary',
    imageSize: '100%',
    path: '/documentary',
  },
];

const PhotographyHome = () => {
  const [activeSection, setActiveSection] = useState(sections[0]);

  return (
    <>
      <SEO title="Portfolio" />
      <StyledPhotographyHome>
        <Navbar dark />
        <div className="inner">
          <div className="left">
            <div className="left-inner">
              {sections.map((item, index) => {
                return (
                  <Link to={`/portfolio/photography/${item.path}`} className="category-link">
                    <h2
                      key={index}
                      className="category"
                      onMouseEnter={() => setActiveSection(item)}
                    >
                      {item.name}
                    </h2>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </StyledPhotographyHome>
    </>
  );
};

const StyledPhotographyHome = styled.section`
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.goldPrimary};
  height: 100vh;

  .inner {
    flex-grow: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
  }

  .left {
    height: auto;
    border: none;
    flex: 0.9;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .left-inner {
      display: flex;
      flex-direction: column;
    }

    .category-link {
      display: inline-block;
      margin-bottom: 1.45rem;

      .category {
        /* font-size: 50px; */
        font-size: 30px;
        text-transform: uppercase;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.darkBluePrimary};
        position: relative;
        z-index: 1;
        display: inline-flex;

        :hover {
          text-decoration: underline double ${({ theme }) => theme.colors.darkBluePrimary};
        }
      }
    }
  }

  .right {
    flex: 0.6;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default PhotographyHome;
